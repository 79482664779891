@keyframes pulse {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.ab-modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.ab-modal-placeholder-container {
  width: 100%;
  padding: 2.5rem;
}

.ab-modal-iframe {
  width: 100%;
  height: 100%;
}

.ab-doc-loader {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 680px;
  height: 490px;
  z-index: 2147483001;
  border-radius: 5px;
  background-color: white;
  padding: 40px
}

.ab-doc-close {
  position: absolute;
  right: -50px;
  top: 10px;
  color: white;
  cursor: pointer;
}

.ab-doc-search-placeholder {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 70px;
  z-index: 2147483001;
  border-radius: 5px;
  background-color: white;
}

.ab-placeholder-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.ab-doc-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.ab-doc-search-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}


.ab-rounded {
  border-radius: 0.25rem;
}

.ab-gray {
  background-color: rgba(229, 231, 235, 1);	
}

.ab-mb-20 {
  margin-bottom: 20px;
}

.ab-hide-widget-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: -1000em;
  opacity: 0;
  z-index: 2147483001;
}

.ab-show-widget-modal {
  position: fixed;
  opacity: 1;
  z-index: 2147483001;
  width: 100%;
  height: 100%;
}

.ab-widget {
  z-index: 2147483001;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.ab-ask-bubble {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 2147483000;
  visibility: visible;
  cursor: pointer;
}

.ab-ask-bubble .ab-ask-bubble-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background-color: white;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 999px;
}

.ab-ask-bubble .ab-ask-bubble-wrapper .ab-ask-bubble-icon {
  margin-right: 12px;
  color: #9CA3AF;
}

.ab-ask-bubble .ab-ask-bubble-wrapper .ab-ask-bubble-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ab-ask-bubble .ab-ask-bubble-wrapper .ab-ask-bubble-content .ab-ask-bubble-title {
  color: #374151;
  font-size: 18px;
  font-family: Inter;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

.ab-ask-bubble .ab-ask-bubble-wrapper .ab-ask-bubble-content .ab-ask-bubble-subtitle {
  color: #6B7280;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word
}

.ab-ask-bubble .ab-ask-bubble-wrapper .ab-ask-notification {
  position: absolute;
  top: 0;
  right: 6px;
  width: 16px;
  height: 16px;
  background: #EF4444;
  border-radius: 999px;
  border: 4px #FECACA solid;
}